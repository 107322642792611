<template>
  <div class="party">
    <OfficialHead ref="header" :homePageSetting="homePageSetting" />
    <!-- banner start -->
    <div class="banner">
      <swiper
        ref="bannerSwiper"
        :options="bannerSwiperOptions"
      >
        <swiper-slide
          v-for="(banner, index) of bannerList"
          :key="index"
        >
          <el-image :src="banner.picUrl" class="banner-img" fit="cover" />
          <a v-if="banner.linkUrl" :href="banner.linkUrl" class="banner-link" target="_blank">
            <span>点击进入</span>
            <span>>></span>
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </div>
    <!-- banner end -->
    <!-- news start -->
    <div class="news">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 news-left" v-if="newsSwiper.length">
            <swiper
              ref="newsSwiper"
              :options="newsSwiperOptions"
            >
              <swiper-slide
                v-for="item of newsSwiper"
                :key="item.id"
              >
                <a class="news-img" :href="getDetailUrl(item)" target="_blank">
                  <el-image :src="item.picture" class="img-src" fit="cover" />
                  <div class="img-title">
                    <span class="title-text">{{ item.title }}</span>
                  </div>
                </a>
              </swiper-slide>
              <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
            </swiper>
          </div>
          <div class="col-12 col-lg-6 news-right" v-if="newsList.length">
            <div class="common-title news-title">
              <div class="title-main">
                <el-image :src="require('@/assets/dev/title-icon.png')" class="main-img" fit="cover" />
                <span class="main-content">学党史 悟思想</span>
              </div>
              <a class="title-sub" :href="`/bigPictureNews?id=${newsQuery.articleListId}`" target="_blank">
                <span>更多</span>
                <span>>></span>
              </a>
            </div>
            <div class="common-list" v-for="(item, index) of newsList" :key="index">
              <a class="list-item" v-for="subItem of item" :key="subItem.id" :href="getDetailUrl(subItem)" target="_blank">
                <div class="item-content">
                  <span class="content-icon"></span>
                  <span class="content-title">{{ subItem.title }}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- news end -->
    <!-- replenish start  -->
    <div class="replenish">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6" v-if="crowdList.length">
            <div class="common-title replenish-title">
              <div class="title-main">
                <el-image :src="require('@/assets/dev/title-icon.png')" class="main-img" fit="cover" />
                <span class="main-content">办实事 开新局</span>
              </div>
              <a class="title-sub" :href="`/bigPictureNews?id=${crowdQuery.articleListId}`" target="_blank">
                <span>更多</span>
                <span>>></span>
              </a>
            </div>
            <a class="common-list">
              <div class="list-item" v-for="item of crowdList" :key="item.id">
                <div class="item-content">
                  <span class="content-icon"></span>
                  <span class="content-title">{{ item.title }}</span>
                </div>
              </div>
            </a>
          </div>
          <div class="col-12 col-lg-6" v-if="partyList.length">
            <div class="common-title replenish-title">
              <div class="title-main">
                <el-image :src="require('@/assets/dev/title-icon.png')" class="main-img" fit="cover" />
                <span class="main-content">感恩党 跟党走</span>
              </div>
              <a class="title-sub" :href="`/bigPictureNews?id=${partyQuery.articleListId}`" target="_blank">
                <span>更多</span>
                <span>>></span>
              </a>
            </div>
            <a class="common-list">
              <div class="list-item" v-for="item of partyList" :key="item.id">
                <div class="item-content">
                  <span class="content-icon"></span>
                  <span class="content-title">{{ item.title }}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- replenish end  -->
    <!-- atlas start -->
    <div class="atlas" v-if="imagesList.length">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="common-title atlas-title">
              <div class="title-main">
                <el-image :src="require('@/assets/dev/title-icon.png')" class="main-img" fit="cover" />
                <span class="main-content">精彩图集</span>
              </div>
              <a class="title-sub" :href="`/bigPictureNews?id=${imagesQuery.articleListId}`" target="_blank">
                <span>更多</span>
                <span>>></span>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-none d-lg-block atlas-list">
              <swiper
                ref="atlasSwiper"
                :options="atlasXlSwiperOptions"
                class="atlas-swiper"
              >
                <a class="swiper-slide" v-for="item of imagesList" :key="item.id" :href="getDetailUrl(item)" target="_blank">
                  <el-image :src="item.picture" class="item-img" fit="cover" />
                  <div class="item-title">{{ item.title }}</div>
                </a>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              </swiper>
            </div>
            <div class="d-lg-none atlas-list-sm">
              <swiper
                ref="atlasSwiper"
                :options="atlasSmSwiperOptions"
                class="atlas-swiper"
              >
                <swiper-slide v-for="item of imagesList" :key="item.id">
                  <div class="item-img-sm">
                    <el-image :src="item.picture" class="img-src" fit="cover" />
                    <div class="img-title">
                      <span class="title-text">{{ item.title }}</span>
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              </swiper>
            </div>
          </div>
      </div>
      </div>
    </div>
    <!-- atlas end -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import { getHomePageSetting, setSeo } from '../api/defaultDataModel'
import OfficialHead from './OfficialHead'
import OfficialFoot from './OfficialFoot'

export default {
  name: 'PartyHistory',
  components: {
    OfficialHead,
    OfficialFoot
  },
  data () {
    return {
      homePageSetting: getHomePageSetting(),
      bannerSwiperOptions: {
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets"
        }
      },
      newsSwiperOptions: {
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      atlasXlSwiperOptions: {
        autoplay: false,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 4,
        spaceBetween: 30
      },
      atlasSmSwiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows : true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      newsQuery: {
        page: 1,
        pageSize: 12,
        articleListId: null
      },
      replenishQuery: {
        page: 1,
        pageSize: 10,
        articleListId: null
      },
      crowdQuery: {
        page: 1,
        pageSize: 5,
        articleListId: null
      },
      partyQuery: {
        page: 1,
        pageSize: 5,
        articleListId: null
      },
      imagesQuery: {
        page: 1,
        pageSize: 8,
        articleListId: null
      },
      bannerList: [
        { picUrl: require('@/assets/banner/history-banner.png'), linkUrl: 'http://dangshi.people.com.cn/' },
        { picUrl: require('@/assets/banner/history-banner_02.png'), linkUrl: '' },
        { picUrl: require('@/assets/banner/history-banner_03.png'), linkUrl: '' }
      ],
      newsSwiper: [],
      newsList: [],
      crowdList: [],
      partyList: [],
      imagesList: []
    }
  },
  created () {
    const { nid, cid, pid, iid } = this.$route.query
    const status = nid && cid && pid && iid && /^\d{15,}$/.test(nid) && /^\d{15,}$/.test(cid) && /^\d{15,}$/.test(pid) && /^\d{15,}$/.test(iid)
    if (status) {
      this.newsQuery.articleListId = nid
      this.crowdQuery.articleListId = cid
      this.partyQuery.articleListId = pid
      this.imagesQuery.articleListId = iid
      this.getSetting()
      this.queryNewsList()
      this.queryCrowdList()
      this.queryPartyList()
      this.queryImagesList()
    } else {
      this.$router.push({ path: '/' })
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.$refs.header.handleScroll)
    this.$refs.header.navigationHeaderClass = ['header', 'header-fixed']
  },
  methods: {
    async getSetting () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/homePageSetting/getSetting')
      const { data, code } = res.data
      if (code === 8000) {
        this.homePageSetting = data
        setSeo(
          this.homePageSetting.seoTitle,
          this.homePageSetting.seoDescription,
          this.homePageSetting.seoKeyword
        )
      }
    },
    async queryNewsList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.newsQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list) && list.length > 5) {
            this.newsSwiper = list.slice(0, 5)
            this.newsList = this.handlePages(list.slice(5, list.length))
          } else {
            this.newsSwiper = list
          }
        }
      }
    },
    async queryCrowdList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.crowdQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.crowdList = list
          }
        }
      }
    },
    async queryPartyList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.partyQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.partyList = list
          }
        }
      }
    },
    async queryImagesList () {
      const res = await this.$http.get(`${this.TzConfig.BASE_URL}/h5/article/list`, { params: this.imagesQuery })
      if (res.status === 200) {
        const { code, data } = res.data
        if (code === 8000) {
          const { list } = data
          if (Array.isArray(list)) {
            this.imagesList = list
          }
        }
      }
    },
    handlePages (list) {
      const pages = []
      list.forEach((item, index) => {
        const page = Math.floor(index / 5)
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    },
    getDetailUrl(news) {
      if (news) {
        if (1 === news.articleType) {
          return `/importantDetail?id=${news.id}`
        }
        return `/detail?id=${news.id}`
      }
      return '/'
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../assets/css/font.css';
  .party {
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    font-family: pingFangSC-Medium;
    color: #222222;
    .common-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      box-sizing: border-box;
      padding: 0 30px 0 0;
      margin-bottom: 30px;
      .title-main {
        display: flex;
        align-items: center;
        width: 82%;
        .main-img {
          margin-right: 20px;
        }
        .main-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: SourceHanSansCN-Heavy;
          font-size: 34px;
          color: #c62b25;
        }
      }
      .title-sub {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .common-list {
      display: block;
      margin-bottom: 25px;
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
      .list-item {
        display: block;
        margin-bottom: 25px;
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
        .item-content {
          display: flex;
          align-items: center;
          font-size: 18px;
          .content-icon {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 20px;
            background: #e9d2b7;
          }
          .content-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
            font-family: SourceHanSerifCN-Medium;
            font-size: 18px;
          }
        }
      }
    }
    .banner {
      position: relative;
      width: 100%;
      height: 360px;
      /deep/ .swiper-container {
        height: 100%;
        .swiper-pagination {
          .swiper-pagination-bullet {
            width: 44px;
            height: 2px;
            background: #fff;
            border-radius: 0;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            background: #f9d688;
          }
        }
        .swiper-button-prev, .swiper-button-next {
          margin-top: -44px;
          width: 80px;
          height: 80px;
          background-color: #f9d688;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 38px;
          border-radius: 50%;
          background-image: url(../assets/images/arr-right-y.png);
        }
        .swiper-button-prev {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
          left: 40px;
        }
        .swiper-button-next {
          right: 40px;
        }
      }
      .banner-img {
        width: 100%;
        height: 100%;
      }
      .banner-link {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 4px 4px;
        border-bottom: 2px solid #e2cea8;
        font-size: 20px;
        font-weight: 700;
        color: #e2cea8;
      }
    }
    .news {
      margin-top: 60px;
      .news-left {
        .swiper-container {
          width: 100%;
          height: 100%;
          .news-img {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            .img-src {
              width: 100%;
              height: 100%;
            }
            .img-title {
              position: absolute;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 70px;
              z-index: 99;
              background: rgba(0, 0, 0, 0.6);
              .title-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                width: 85%;
                font-size: 18px;
                color: #ffffff;
              }
            }
          }
          .swiper-button-prev,
          .swiper-button-next {
            top: inherit;
            bottom: 24px;
            width: 20px;
            height: 20px;
            background-size: 27px 30px;
            cursor: pointer;
          }
        }
      }
      .news-right {
        height: 100%;
        margin-top: -6px;
        .news-title {
          background: url('../assets/dev/replenish-bg.png') no-repeat;
          background-position-x: 100%;
          background-size: 70% 100%;
        }
      }
    }
    .replenish {
      margin-top: 60px;
      .replenish-title {
        background: url('../assets/dev/replenish-bg.png') no-repeat;
        background-position-x: 100%;
        background-size: 70% 100%;
      }
    }
    .atlas {
      padding: 60px 0;
      margin-top: 60px;
      background: #fef7e6;
      .atlas-title {
        background: url('../assets/dev/atlas-bg.png') no-repeat;
        background-position-x: 100%;
        background-size: 90% 100%;
      }
      .atlas-list {
        position: relative;
        .item-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 20px;
          font-family: sourceHanSerifCN-Medium;
          font-size: 18px;
        }
        .atlas-swiper {
          position: initial;
          .item-img {
            width: 100%;
            height: 190px;
          }
          .swiper-button-prev {
            width: 30px;
            height: 80px;
            background: url('../assets/dev/atlas-prev.png');
            top: 35%;
            left: -45px;
          }
          .swiper-button-next {
            width: 30px;
            height: 80px;
            background: url('../assets/dev/atlas-next.png');
            top: 35%;
            right: -45px;
          }
        }
      }
      .atlas-list-sm {
        .swiper-slide {
          width: 80% !important;
          .item-img-sm {
            position: relative;
            width: 100%;
            .img-src {
              width: 100%;
              height: 190px;
            }
            .img-title {
              position: absolute;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 68px;
              font-size: 18px;
              color: #ffffff;
              z-index: 99;
              background: rgba(0, 0, 0, 0.6);
              .title-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                width: 85%;
              }
            }
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          display: none;
        }
      }
    }
    .footer {
      margin-top: 0px;
    }
  }
  @media (max-width: 992px) {
    .party {
      padding-top: 64px;
      .news {
        .news-right {
          margin-top: 20px;
        }
      }
      .replenish {
        margin-top: 10px;
        .col-12 {
          .replenish-title {
            margin-top: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .party {
      padding-top: 64px;
      .common-title {
        margin-bottom: 20px;
        .title-main {
          .main-img {
            width: 52px;
            height: 50px;
          }
          .main-content {
            font-size: 24px;
          }
        }
        .title-sub {
          font-size: 14px;
        }
      }
      .common-list {
        margin-bottom: 20px;
        .list-item {
          margin-bottom: 20px;
          .item-title {
            margin-bottom: 20px;
            font-size: 24px;
          }
          .item-content {
            margin-bottom: 20px;
            .content-icon {
              margin-right: 15px;
            }
            .content-title {
              font-size: 16px;
            }
          }
        }
      }
      .news {
        margin-top: 40px;
        .news-left {
          .swiper-container {
            .news-img {
              .img-title {
                height: 64px;
                .title-text {
                  font-size: 16px;
                }
              }
            }
            .swiper-button-prev,
            .swiper-button-next {
              bottom: 23px;
              width: 18px;
              height: 18px;
            }
            .swiper-button-prev {
              left: 8px;
            }
            .swiper-button-next {
              right: 8px;
            }
          }
        }
      }
      .replenish {
        .col-12 {
          .replenish-title {
            margin-top: 0;
          }
        }
      }
      .atlas {
        margin-top: 20px;
        padding: 50px 0;
        .atlas-list-sm {
          .swiper-slide {
            .item-img-sm {
              .img-title {
                height: 64px;
                .title-text {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
